

import {backendForcastLookup, backendLookup} from './backendlookup'

export function apiLocationForecast(lat, lng, callback) {
  // https://api.weather.gov/points/40.24,-74.8772 
    var endpoint = `https://api.weather.gov/points/${lat},${lng}`
    backendForcastLookup("GET", endpoint, callback)   
  }

export function apiLocationForecastPeriods(forecast_url, callback) {
  var endpoint = `${forecast_url}`
  //https://api.weather.gov/gridpoints/PHI/57,89/forecast
  backendForcastLookup("GET", endpoint, callback)   
}

export function apiLocationForecastHourly(forecast_hourly_url, callback) {
  var endpoint = `${forecast_hourly_url}`
  backendForcastLookup("GET", endpoint, callback)   
}



export function apiImageLoading(prefixurl, callback) {
  const endpointUrl = '/cfg/loadingdots/'
  backendLookup("GET",prefixurl, endpointUrl, callback)   
}