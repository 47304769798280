//////////////////////////////////////////////////////////////////////////    
//
//      The weather icons are derived from
//      'https://erikflowers.github.io/weather-icons/'
//      Logic needs to be improved for better weather icon accuracy
//
////////////////////////////////////////////////////////////////////////     

import React, {useState, useEffect} from 'react';
import 'weather-icons/css/weather-icons.css'


export function WeatherIcon(props) {
    const {currShortForecast} = props
    const {isDaytime} = props
    const [isIcon, setIsIcon] = useState(null) 
    

    useEffect(() => {
        const handleWeatherIcon = (currShortForecast) => {
            if (currShortForecast !== null) {
                if (isDaytime) {
                    // sunny
                    if(currShortForecast.indexOf("Clear") >= 0) {
                        setIsIcon('wi wi-day-sunny')
                    }
                    if(currShortForecast.indexOf("Mostly Clear") >= 0) {
                        setIsIcon('wi wi-day-sunny-overcast')
                    }
                    if(currShortForecast.indexOf("Sunny") >= 0) {
                        setIsIcon('wi wi-day-sunny')
                    }
                    if(currShortForecast.indexOf("Mostly Sunny") >= 0) {
                        setIsIcon('wi wi-day-sunny-overcast')
                    }
                    if(currShortForecast.indexOf("Partly Sunny") >= 0) {
                        setIsIcon('wi wi-day-cloudy')
                    }
                    // cloudy
                    if(currShortForecast.indexOf("Cloudy") >= 0) {
                        setIsIcon('wi wi-cloudy')
                    }
                    if(currShortForecast.indexOf("Partly Cloudy") >= 0) {
                        setIsIcon('wi wi-day-sunny-overcast')
                    }
                    if(currShortForecast.indexOf("Mostly Cloudy") >= 0) {
                        setIsIcon('wi wi-day-cloudy')
                    }
                    //rain or snow or shower
                    if(currShortForecast.indexOf("Rain") >= 0) {
                        if(currShortForecast.indexOf("Thunderstorms") >= 0) {
                            setIsIcon('wi wi-thunderstorm')
                        } else {
                            setIsIcon('wi wi-day-rain')
                        }
                    }  else if (currShortForecast.indexOf("Snow") >= 0) {
                        setIsIcon('wi wi-day-snow')
                        if(currShortForecast.indexOf("Thunderstorms") >= 0) {
                            setIsIcon('wi wi-day-snow-thunderstorm')
                        }
                    } else if (currShortForecast.indexOf("Showers") >= 0) {
                        if(currShortForecast.indexOf("Thunderstorms") >= 0) {
                            setIsIcon('wi wi-thunderstorm')
                        } else {
                            setIsIcon('wi wi-day-rain')
                        }
                    }
                } else {  // night
                    // sunny
                    if(currShortForecast.indexOf("Clear") >= 0) {
                        setIsIcon('wi wi-night-clear')
                    }
                    if(currShortForecast.indexOf("Mostly Clear") >= 0) {
                        setIsIcon('wi wi-night-alt-partly-cloudy')
                    }
                    if(currShortForecast.indexOf("Partly Clear") >= 0) {
                        setIsIcon('wi wi-night-alt-cloudy')
                    }
                    // cloudy
                    if(currShortForecast.indexOf("Cloudy") >= 0) {
                        setIsIcon('wi wi-night-alt-cloudy')
                    }
                    if(currShortForecast.indexOf("Mostly Cloudy") >= 0) {
                        setIsIcon('wi wi-night-alt-cloudy')
                    }
                    if(currShortForecast.indexOf("Partly Cloudy") >= 0) {
                        setIsIcon('wi wi-night-alt-partly-cloudy')
                    }
                    //rain or snow
                    if(currShortForecast.indexOf("Rain") >= 0) {
                        setIsIcon('wi wi-night-alt-rain')
                        if(currShortForecast.indexOf("Thunderstorms") >= 0) {
                            setIsIcon('wi wi-night-thunderstorm')
                        }
                    }  else {
                        if(currShortForecast.indexOf("Snow") >= 0) {
                            setIsIcon('wi wi-night-alt-snow')
                        }
                        if(currShortForecast.indexOf("Thunderstorms") >= 0) {
                            setIsIcon('wi wi-night-snow-thunderstorm')
                        }
                    }
                }
            }
        }
        
        if (isDaytime) {
            setIsIcon('wi wi-day-sunny')
        } else {
            setIsIcon('wi wi-night-clear')
        }
        handleWeatherIcon(currShortForecast);
    }, [props, isDaytime,currShortForecast  ])

    return (
        <i className={isIcon} />
    )
}


