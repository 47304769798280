
import React,  { Component }  from 'react';
import {Weather} from './app_component/weather.component';
import './App.css';
import { UrlProvider } from './app_component/prefixurl';

class App extends Component {

  render() {
    const {lat} = this.props
    const {lng} = this.props
    const {city} = this.props
    const {state} = this.props
    const {prefixurl} = this.props

    return (

      <UrlProvider value={prefixurl}>
        <Weather lat={lat} lng={lng} city={city} state={state} />
      </UrlProvider>

    ) 
  }
}

export default App;

