import React, {useContext, useEffect, useState} from 'react';
import {apiImageLoading} from './lookup';
import UrlContext from './prefixurl';
// import loadingdots from './assets/images/loadingdots.gif';


export function ImageLoading(props) {
    const prefixurl = useContext(UrlContext)
    const [didEdit, setdidEdit] = useState(false)
    const [imgSrc, setImgSrc] = useState(null)
    
    useEffect(() => {
        if (didEdit === false){
            setdidEdit(true)
            const handleLoadSrc = (response, status) => {
                if (status === 200){
                    setImgSrc(response.loadingimg_url)
                }
            }
            apiImageLoading(prefixurl, handleLoadSrc)
        }
    }, [prefixurl, props, didEdit, setdidEdit])
    

    return (
        <div>
            <div>
                <div style={{textAlign:'center'}}>
                    <img
                        src={imgSrc}
                        // src={loadingdots}
                        alt=''
                    />
                </div>
            </div>
        </div>
    )
}