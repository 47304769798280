import React, {useEffect, useState, Suspense } from 'react';

// third party packages
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";
import {DateTime} from 'luxon';

//local
import {apiLocationForecast, apiLocationForecastPeriods, apiLocationForecastHourly} from './lookup';
import {WeatherIcon} from './weathericon';
import WeatherCard from "./weathercard";
import {ImageLoading} from './loading'

export function Weather(props) {
    const {lat} = props
    const {lng} = props
    const {city} = props
    const {state} = props

    // Current time
    const [currTemp, setCurrTemp] = useState(null)
    const [currShortForecast, setcurrShortForecast] = useState(null)
    const [currWindSpeed, setCurrWindSpeed] = useState(null)
    const [currWindDirection, setCurrWindDirection] = useState(null)
    const [isDaytime, setIsDaytime] = useState(true)

    // Day A
    const [aDate, setADate] = useState(null)
    const [aDaytemp, setADayTemp] = useState(null)
    const [aDayShortForecast, setADayShortForecast] = useState(null)
    const [aDayisDaytime, setADayIsDaytime] = useState(true)
    const [aNighttemp, setANightTemp] = useState(null)
    const [aNightShortForecast, setANightShortForecast] = useState(null)
    
    // Day B
    const [bDate, setBDate] = useState(null)
    const [bDaytemp, setBDayTemp] = useState(null)
    const [bDayShortForecast, setBDayShortForecast] = useState(null)
    const [bDayisDaytime, setBDayIsDaytime] = useState(true)
    const [bNighttemp, setBNightTemp] = useState(null)
    const [bNightShortForecast, setBNightShortForecast] = useState(null)

    // Day C
    const [cDate, setCDate] = useState(null)
    const [cDaytemp, setCDayTemp] = useState(null)
    const [cDayShortForecast, setCDayShortForecast] = useState(null)
    const [cDayisDaytime, setCDayIsDaytime] = useState(true)
    const [cNighttemp, setCNightTemp] = useState(null)
    const [cNightShortForecast, setCNightShortForecast] = useState(null)

    // Day D
    const [dDate, setDDate] = useState(null)
    const [dDaytemp, setDDayTemp] = useState(null)
    const [dDayShortForecast, setDDayShortForecast] = useState(null)
    const [dDayisDaytime, setDDayIsDaytime] = useState(true)
    const [dNighttemp, setDNightTemp] = useState(null)
    const [dNightShortForecast, setDNightShortForecast] = useState(null)

    // Day E
    const [eDate, setEDate] = useState(null)
    const [eDaytemp, setEDayTemp] = useState(null)
    const [eDayShortForecast, setEDayShortForecast] = useState(null)
    const [eDayisDaytime, setEDayIsDaytime] = useState(true)
    const [eNighttemp, setENightTemp] = useState(null)
    const [eNightShortForecast, setENightShortForecast] = useState(null)

    // Day F
    const [fDate, setFDate] = useState(null)
    const [fDaytemp, setFDayTemp] = useState(null)
    const [fDayShortForecast, setFDayShortForecast] = useState(null)
    const [fDayisDaytime, setFDayIsDaytime] = useState(true)
    const [fNighttemp, setFNightTemp] = useState(null)
    const [fNightShortForecast, setFNightShortForecast] = useState(null)
  

    const mediaMatch = window.matchMedia('(min-width: 900px)');
    const [matches, setMatches] = useState(mediaMatch.matches);

    let i = 0;

    const handleForecastLookup = async (response, status) => {
        if (status === 200){
            const handleForecasthourlyLookup = (response, status) => {
                if (status === 200) {
                    setCurrTemp(response.properties.periods[0].temperature)
                    setcurrShortForecast(response.properties.periods[0].shortForecast)
                    setCurrWindSpeed(response.properties.periods[0].windSpeed)
                    setCurrWindDirection(response.properties.periods[0].windDirection)
                    setIsDaytime(response.properties.periods[0].isDaytime)
                    // setDoneLoadingHourlyLookup(true)
                } else {
                    i++
                    if (i <20) {
                        apiLocationForecast(lat, lng, handleForecastLookup)
                    }
                }
            }
            
            const handleForecastPeriodsLookup = (response, status) => {
                if (status === 200) {
                    const dayone   = DateTime.fromISO(response.properties.generatedAt).plus({ days: 1 }).toFormat('D')
                    const daytwo   = DateTime.fromISO(response.properties.generatedAt).plus({ days: 2 }).toFormat('D')
                    const daythree = DateTime.fromISO(response.properties.generatedAt).plus({ days: 3 }).toFormat('D')
                    const dayfour  = DateTime.fromISO(response.properties.generatedAt).plus({ days: 4 }).toFormat('D')
                    const dayfive  = DateTime.fromISO(response.properties.generatedAt).plus({ days: 5 }).toFormat('D')
                    const daysix   = DateTime.fromISO(response.properties.generatedAt).plus({ days: 6 }).toFormat('D')

                    for (let i = 0; i < response.properties.periods.length; i++) {
                        var someday = DateTime.fromISO(response.properties.periods[i].startTime).toFormat('D') 
                        // console.log("response.properties.periods[i]",response.properties.periods[i])
                        if (dayone === someday ) {
                            setADate(DateTime.fromISO(response.properties.periods[i].startTime).toFormat('EEE d') )
                            if (response.properties.periods[i].isDaytime){
                                setADayTemp(response.properties.periods[i].temperature)
                                setADayShortForecast(response.properties.periods[i].shortForecast)
                                setADayIsDaytime(response.properties.periods[i].isDaytime)
                            } else {
                                setANightTemp(response.properties.periods[i].temperature)
                                setANightShortForecast(response.properties.periods[i].shortForecast)
                            }
                        } else if (daytwo === someday ) {
                            setBDate(DateTime.fromISO(response.properties.periods[i].startTime).toFormat('EEE d') )
                            if (response.properties.periods[i].isDaytime){
                                setBDayTemp(response.properties.periods[i].temperature)
                                setBDayShortForecast(response.properties.periods[i].shortForecast)
                                setBDayIsDaytime(response.properties.periods[i].isDaytime)

                            } else {
                                setBNightTemp(response.properties.periods[i].temperature)
                                setBNightShortForecast(response.properties.periods[i].shortForecast)
                            }
                        } else if (daythree === someday ) {
                            setCDate(DateTime.fromISO(response.properties.periods[i].startTime).toFormat('EEE d') )
                            if (response.properties.periods[i].isDaytime){
                                setCDayTemp(response.properties.periods[i].temperature)
                                setCDayShortForecast(response.properties.periods[i].shortForecast)
                                setCDayIsDaytime(response.properties.periods[i].isDaytime)

                            } else {
                                setCNightTemp(response.properties.periods[i].temperature)
                                setCNightShortForecast(response.properties.periods[i].shortForecast)
                            }
                        } else if (dayfour === someday ) {
                            setDDate(DateTime.fromISO(response.properties.periods[i].startTime).toFormat('EEE d') )
                            if (response.properties.periods[i].isDaytime){
                                setDDayTemp(response.properties.periods[i].temperature)
                                setDDayShortForecast(response.properties.periods[i].shortForecast)
                                setDDayIsDaytime(response.properties.periods[i].isDaytime)

                            } else {
                                setDNightTemp(response.properties.periods[i].temperature)
                                setDNightShortForecast(response.properties.periods[i].shortForecast)
                            }
                        } else if (dayfive === someday ) {
                            setEDate(DateTime.fromISO(response.properties.periods[i].startTime).toFormat('EEE d') )
                            if (response.properties.periods[i].isDaytime){
                                setEDayTemp(response.properties.periods[i].temperature)
                                setEDayShortForecast(response.properties.periods[i].shortForecast)
                                setEDayIsDaytime(response.properties.periods[i].isDaytime)

                            } else {
                                setENightTemp(response.properties.periods[i].temperature)
                                setENightShortForecast(response.properties.periods[i].shortForecast)
                            }
                        } else if (daysix === someday ) {
                            setFDate(DateTime.fromISO(response.properties.periods[i].startTime).toFormat('EEE d') )
                            if (response.properties.periods[i].isDaytime){
                                setFDayTemp(response.properties.periods[i].temperature)
                                setFDayShortForecast(response.properties.periods[i].shortForecast)
                                setFDayIsDaytime(response.properties.periods[i].isDaytime)
                            } else {
                                setFNightTemp(response.properties.periods[i].temperature)
                                setFNightShortForecast(response.properties.periods[i].shortForecast)
                            }
                        }
                    }
                    // setDoneLoadingPeriodLookup(true)
                } else {
                    i++
                    if (i <20) {
                        apiLocationForecast(lat, lng, handleForecastLookup)
                    }
                }
            }
        
            const forecast_url = response.properties.forecast
            const forecast_hourly_url = response.properties.forecastHourly
            await apiLocationForecastHourly(forecast_hourly_url, handleForecasthourlyLookup )
            await apiLocationForecastPeriods(forecast_url, handleForecastPeriodsLookup)
        
        } else {
            i++
            if (i < 20) {
                apiLocationForecast(lat, lng, handleForecastLookup)
            }
        }
    }

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });
    
    useEffect(() => {
        async function fetchData() {
          await apiLocationForecast(lat, lng, handleForecastLookup)
        }
        fetchData();
    }, [lat, lng]);


    const styles = {
        container: isRowBased => ({
          display: 'flex',
          flexDirection: isRowBased ? 'row' : 'row',
        //   justifyContent: isRowBased ? 'center':'flex-start',
          justifyContent: 'flex-start',
          whiteSpace: 'nowrap', 
          overflow: 'scroll'
        })
    };


    return (
        <div>
            <Suspense
                fallback={<ImageLoading />}
            >
            <div>
                <div>
                    <Card variant='outlined' style={{"backgroundColor": "#172734"}}> 
                        <CardContent>
                            <Typography
                                style={{color:"#ffffff"}}
                                color="textSecondary" align='center' gutterBottom={true} variant='h5'>
                                    {city}{' '}{state}
                            </Typography>
                            <Typography style={{color:"#ffffff"}} color="textSecondary" align='center' variant='h5' gutterBottom={true}>
                                {currShortForecast}
                            </Typography>
                            <div style={{'display':'flex', "backgroundColor": "#172734"}}>
                                <div style={{'width':'80%', 'paddingRight':'30px', 'paddingTop':'10px'}}>
                                    <Typography style={{color:"#ffffff"}} color="textSecondary" align='right' variant='h3'>
                                        <WeatherIcon currShortForecast={currShortForecast} isDaytime={isDaytime} />
                                    </Typography>
                                </div>
                                <div style={{'width':'100%', 'paddingLeft':'20px', 'paddingTop':'10px'}}>
                                    <Typography style={{color:"#ffffff"}} color="textSecondary" align='left' variant='h5' gutterBottom={true}>  
                                        <span className='wi wi-thermometer'>
                                            <span className='wi wi-fahrenheit'>&nbsp;&nbsp;&nbsp;</span>
                                            {currTemp}&deg;
                                        </span>
                                    </Typography>
                                    <Typography style={{color:"#ffffff"}} color="textSecondary" align='left' variant='h5'>
                                        <span className='wi wi-strong-wind'>
                                            &nbsp;&nbsp;&nbsp;{currWindSpeed}&nbsp;{currWindDirection}
                                        </span>
                                    </Typography>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
                <div className='weathercardbox' style={styles.container(matches)}>
                <Suspense
                fallback={<ImageLoading />}
                >   
                    <WeatherCard 
                        date                = {aDate}
                        isDaytime           = {aDayisDaytime}                    
                        currShortForecast   = {aDayShortForecast}
                        nightShortForecast  = {aNightShortForecast}
                        daytemp             = {aDaytemp}
                        nighttemp           = {aNighttemp}
                    /> 
                </Suspense>  
                <Suspense
                fallback={<ImageLoading />}
                > 
                    <WeatherCard 
                        date={bDate}
                        isDaytime           = {bDayisDaytime}
                        currShortForecast   = {bDayShortForecast}
                        nightShortForecast  = {bNightShortForecast}
                        daytemp             = {bDaytemp}
                        nighttemp           = {bNighttemp}
                    />
                </Suspense>
                <Suspense
                fallback={<ImageLoading />}
                >
                    <WeatherCard 
                        date={cDate}
                        isDaytime           = {cDayisDaytime}
                        currShortForecast   = {cDayShortForecast}
                        nightShortForecast  = {cNightShortForecast}
                        daytemp             = {cDaytemp}
                        nighttemp           = {cNighttemp}
                    />
                </Suspense>
                <Suspense
                fallback={<ImageLoading />}
                >   
                    <WeatherCard 
                        date={dDate}
                        isDaytime           = {dDayisDaytime}
                        currShortForecast   = {dDayShortForecast}
                        nightShortForecast  = {dNightShortForecast}
                        daytemp             = {dDaytemp}
                        nighttemp           = {dNighttemp}
                    />
                </Suspense>
                <Suspense
                fallback={<ImageLoading />}
                >   
                    <WeatherCard 
                        date                = {eDate}
                        isDaytime           = {eDayisDaytime}
                        currShortForecast   = {eDayShortForecast}
                        nightShortForecast  = {eNightShortForecast}
                        daytemp             = {eDaytemp}
                        nighttemp           = {eNighttemp}
                    />
                </Suspense>  
                <Suspense
                fallback={<ImageLoading />}
                >
                    <WeatherCard 
                        date                = {fDate}
                        isDaytime           = {fDayisDaytime}
                        currShortForecast   = {fDayShortForecast}
                        nightShortForecast  = {fNightShortForecast}
                        daytemp             = {fDaytemp}
                        nighttemp           = {fNighttemp}
                    />
                </Suspense>
                </div>
            </div>
            </Suspense>
        </div>
    )
}






