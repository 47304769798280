import React from "react";
import { withStyles } from '@mui/styles'; // deprecated function - find alternative
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";
import {WeatherIcon} from './weathericon';

const styles = {
  card: {
    display: "inline-block",
    backgroundColor: "#172734",
    borderRadius: 10,
    margin:'1px',
    minWidth:'100px'
  },
  cardcontent: {
      padding: "10px"
  }
};


function SimpleCard(props) {
    const {date} = props
    const {currShortForecast} = props
    const {isDaytime} = props
    const {nightShortForecast} = props
    const {daytemp} = props
    const {nighttemp} = props
    const { classes } = props;

  return (
    <div style={{ display: "inline-block" }}>
      <Card style={{backgroundColor: "#172734"}} className={classes.card} variant='outlined'>
        <CardContent style={{'width':'126px'}}>
            <Typography style={{color:'#ffffff'}} align='center' gutterBottom variant='h6'>
                {date}
            </Typography>
            <div style={{'display':'flex'}}>
                <div style={{'width':'100%'}}>
                  <Typography style={{color:'#ffffff'}} align='center' variant='h4'>
                        <WeatherIcon currShortForecast={currShortForecast} isDaytime={isDaytime} />
                    </Typography>
                    <Typography style={{color:'#ffffff'}} align='center' variant='h6'>
                        {daytemp}&deg;
                    </Typography>
                </div>
                <div style={{'width':'100%'}}>
                    {nightShortForecast !== null ?  
                        <Typography style={{color:'#ffffff'}} align='center' variant='h4'>
                            <WeatherIcon currShortForecast={nightShortForecast} isDaytime={!isDaytime} />
                        </Typography>
                    :null
                    }
                    {nightShortForecast !== null ?
                        <Typography style={{color:'#ffffff'}} align='center' variant='h6'>
                            {nighttemp}&deg;
                        </Typography>
                        :null
                    }
                </div>
            </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default withStyles(styles)(SimpleCard);
