import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const e = React.createElement
const ooimawforcastEl = document.getElementById('ooima-loc-wforcast')

if (ooimawforcastEl) {
  ReactDOM.createRoot(ooimawforcastEl).render(e(App, ooimawforcastEl.dataset));
}

